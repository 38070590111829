<template>
  <section class="statisticAvailable">
    <div :class="['statisticAvailable__content', 'collapse__container', { 'collapse-active': !isCollapseOpen }]">
      <div class="statisticAvailable__header collapse__header">
        <p class="statisticAvailable__title">{{ `${total} habitaciones en todo models 1a` }}</p>
        <button class="collapse__button" type="button" @click="toggleCollapse"><iconic name="chevronUp" /></button>
      </div>
      <div class="statisticAvailable__wrapper collapse__body">
        <pie-chart :donut="true" :data="dataToPie" height="198px" width="198px" :library="chartOptions"></pie-chart>
        <div class="statisticAvailable__contentValues">
          <div class="statisticAvailable__itemTotal" v-for="(totalsRoom, key, idx) in totalsRooms" :key="key" :id="key">
            <div class="statisticAvailable__contentName">
              <div class="statisticAvailable__colorPie" :style="`background: ${chartOptions.colors[idx]};`" />
              <span class="statisticAvailable__name">{{ key }}</span>
            </div>
            <span class="statisticAvailable__totalData">{{ totalsRoom }}</span>
          </div>
          <div class="statisticAvailable__globales">
            <span class="statisticAvailable__name">Global</span>
            <span class="statisticAvailable__totalData">{{ global }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: ["totalsRooms", "total", "global"],
    data() {
      return {
        isCollapseOpen: true,
        heightFather: 0,
        chartOptions: {
          responsive: true,
          colors: ["#BD0909", "#209E0C", "#9D9D9D"],
          plotOptions: {
            pie: {
              showInLegend: false,
              dataLabels: {
                enabled: false,
              },
            },
          },
          tooltip: {
            formatter: function() {
              const percentage = Math.round(this.point.percentage);
              return `${this.point.name}: ${percentage}%`;
            },
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            style: {
              color: "#fff",
              "text-transform": "Capitalize",
            },
          },
        },
      };
    },
    methods: {
      toggleCollapse: function() {
        const fatherElement = document.querySelector(".statisticAvailable");

        if (this.isCollapseOpen) {
          this.heightFather = fatherElement.offsetHeight;
        }
        this.$emit("toggleElementCollapse", {
          fatherIdOrClass: `.statisticAvailable`,
          fatherHeight: this.heightFather,
          isCollapseToClose: this.isCollapseOpen,
          fatherHeightCollapsed: 60,
          key: "available",
        });
        this.isCollapseOpen = !this.isCollapseOpen;
      },
    },
    computed: {
      dataToPie() {
        return Object.entries(this.totalsRooms).map(([key, val]) => [key, val]);
      },
    },
    mounted() {
      const ocupadas = document.getElementById("ocupadas");
      const disponibles = document.getElementById("disponibles");
      const deshabilitadas = document.getElementById("deshabilitadas");
      const contenedor = ocupadas.parentNode;
      contenedor.appendChild(disponibles);
      contenedor.appendChild(deshabilitadas);
    },
  };
</script>

<style lang="scss">
  .statisticAvailable {
    height: auto;
    &__content {
      @include Flex(column, flex-start, flex-start);
      gap: 15px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      height: 100%;
      box-shadow: 0px 1px 4px 0px #00000040;
      padding-bottom: 15px;
      .collapse__header {
        padding: 15px;
      }
    }
    &__title {
      font-family: $sec-font;
      font-size: 24px;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &__wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;
      margin-top: 42px;
    }
    &__contentValues {
      @include Flex(column);
      gap: 10px;
    }
    &__itemTotal {
      @include Flex(row, space-between);
      width: 200px;
      gap: 5px;
    }
    &__contentName {
      @include Flex(row);
      gap: 5px;
    }
    &__colorPie {
      width: 11px;
      height: 8px;
    }
    &__name {
      font-size: 18px;
      text-transform: capitalize;
    }
    &__totalData {
      @include Flex(row);
      width: 40px;
      height: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background: $primary-color;
      border-radius: 9px;
    }
    &__globales {
      @include Flex(row);
      gap: 86px;
      &:nth-child(2) {
        padding-left: 15px;
      }
    }
    @media (max-width: 700px) {
      &__wrapper {
        flex-wrap: wrap;
      }
    }
  }
</style>
